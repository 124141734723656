import React, { useEffect, useRef } from 'react'
import styled, {
  css,
  StyledComponent,
  useTheme,
} from '@xstyled/styled-components'
import { breakpoints } from '@xstyled/system'
import gsap from 'gsap'

const Container = styled.div`
  pointer-events: none;
  display: none;

  ${breakpoints({
    md: css`
      transition: opacity 140ms ease-in-out;
      position: fixed;
      top: 0;
      left: 0;
      display: flex;
      cursor: pointer;
      align-items: center;
      font-family: title;
      font-size: md;
      justify-content: center;
      text-transform: uppercase;
      width: 80px;
      height: 80px;
      z-index: 3;
    `,
  })}
`

const Progress = styled.circle`
  stroke-dasharray: ${80 * Math.PI};
  stroke-dashoffset: 0;
`

const Graphic = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Text: StyledComponent<
  'span',
  Record<string, unknown>,
  { variant: string },
  never
> = styled.span`
  overflow: hidden;
  display: block;
  color: secondary;
  line-height: 16px;
`

export const VideoCursor: React.FC<{
  variant: 'light' | 'dark'
  playing: boolean
}> = ({ variant = 'light', playing }) => {
  const circleRef = useRef()
  const containerRef = useRef()
  const textRef = useRef()
  const theme = useTheme()

  function onMouseMove(event) {
    const target = event.target as HTMLElement
    const targetTag = target.tagName
    const posX = event.pageX - 40
    const posY = event.pageY - 50
    const isHover =
      targetTag === 'path' || targetTag === 'svg' || target.dataset.url

    gsap.set(containerRef.current, {
      x: posX,
      y: posY,
      opacity: isHover ? 0 : 1,
    })
  }

  useEffect(() => {
    window.addEventListener('mousemove', onMouseMove)

    gsap.set(circleRef.current, {
      transformOrigin: 'center',
    })

    return () => {
      window.removeEventListener('mousemove', onMouseMove)
    }
  }, [])

  useEffect(() => {
    gsap.fromTo(
      circleRef.current,
      0.5,
      {
        scale: 1,
      },
      {
        scale: 0.9,
        yoyo: true,
      },
    )
  }, [playing])

  return (
    <Container ref={containerRef}>
      <Graphic viewBox="0 0 80 80">
        <Progress
          ref={circleRef}
          cx="40"
          cy="40"
          r="38"
          fill="transparent"
          stroke={theme.colors.secondary}
          strokeOpacity={0.72}
          strokeWidth="2"
        />
      </Graphic>
      <Text ref={textRef} variant={variant}>
        {playing ? 'Pause' : 'Play'}
      </Text>
    </Container>
  )
}
