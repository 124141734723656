import styled, { css } from '@xstyled/styled-components'
import { breakpoints } from '@xstyled/system'
import { siteWrapperCss } from '@/modules/utils/siteWrapper'
import proportion from '@/modules/utils/proportion'
import ReactPlayer from 'react-player'

export const VideoShellWrap = styled.div`
  cursor: pointer;
  height: 100%;
  position: relative;
`
export const FullscreenWrap = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000000;
`

export const StyledPlayer = styled(ReactPlayer)`
  position: absolute;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
`

export const ControlsBar = styled.div`
  font-family: title;
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: ${proportion(200, 'desktop')};
  z-index: 2;
  width: 100%;

  ${breakpoints({
    md: css`
      bottom: ${proportion(120, 'desktop')};
    `,
    xl: css`
      bottom: 120px;
    `,
  })}
`

export const Wrapper = styled.div`
  padding: 0 20px;
  ${siteWrapperCss}
`
