import React, { useRef, useState } from 'react'
import Helmet from 'react-helmet'
import { VideoControls } from './VideoControls'
import { VideoCursor } from './VideoCursor'
import {
  FullscreenWrap,
  StyledPlayer,
  VideoShellWrap,
  ControlsBar,
  Wrapper,
} from './styled'

interface Props {
  source: string
}

export const VideoPlayer: React.FC<Props> = ({ info = null, source }) => {
  const videoRef = useRef(null)
  const [playing, setPlaying] = useState(false)
  const [duration, setDuration] = useState(0)
  const [volume] = useState(0.8)
  const [muted] = useState(false)
  const [progress, setProgress] = useState(0)

  const onTogglePlay = () => {
    setPlaying((value) => !value)
  }

  const onProgress = ({ played }) => {
    setProgress(played)
  }

  const onDuration = (value) => {
    setDuration(value)
  }

  const onSetProgress = (value) => {
    videoRef.current.seekTo(value)
  }

  return (
    <>
      <Helmet
        link={[
          {
            href: '/fonts.css',
            rel: 'stylesheet',
            type: 'text/css',
          },
        ]}
      />
      <FullscreenWrap>
        <VideoCursor playing={playing} />
        <VideoShellWrap data-url>
          <StyledPlayer
            onClick={onTogglePlay}
            playing={playing}
            volume={volume}
            muted={muted}
            ref={videoRef}
            width="100%"
            height="100%"
            url={source}
            onProgress={onProgress}
            onDuration={onDuration}
            progressInterval={1000 / 60}
          />
          <ControlsBar>
            <Wrapper>
              {info}
              <VideoControls
                progress={progress}
                duration={duration}
                onUpdateProgress={onSetProgress}
              />
            </Wrapper>
          </ControlsBar>
        </VideoShellWrap>
      </FullscreenWrap>
    </>
  )
}
