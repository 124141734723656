import React, { useRef } from 'react'
import styled, { Box } from '@xstyled/styled-components'

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100px;
  color: #ffffff;

  span {
    font-family: title;
    font-size: 20px;
    text-transform: uppercase;
    line-height: 20px;
  }
`

const ProgressWrap = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  flex: 1;
  height: 100%;
`

const Progress = styled.svg`
  width: 100%;
  height: 1px;
`

const Knob = styled.svg<{ progress: number }>`
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: ${(props) => props.progress * 100}%;
  width: 19px;
  height: 19px;
`

const secondsToText = (value) => {
  const minutes = Math.floor(value / 60)
    .toString()
    .padStart(2, '0')

  const seconds = Math.floor(value % 60)
    .toString()
    .padStart(2, '0')

  return [minutes, seconds].join(':')
}

export function VideoControls({
  progress = 0.4,
  duration = 0,
  onUpdateProgress,
}) {
  const progressBarRef = useRef(null)

  function onSetProgress(event) {
    const bounds = progressBarRef.current.getBoundingClientRect()
    const value = (event.clientX - bounds.left) / bounds.width

    onUpdateProgress(value)
  }

  return (
    <Container>
      <Box pr="22px" height="100%" display="flex" alignItems="center">
        <span>{secondsToText(duration * progress)}</span>
      </Box>
      <ProgressWrap
        ref={progressBarRef}
        flex="1"
        position="relative"
        onClick={onSetProgress}
      >
        <Progress viewBox="0 0 100 2" preserveAspectRatio="none">
          <path
            d="M0 0 L100 0"
            strokeWidth="2"
            stroke="#ffffff"
            strokeOpacity={0.4}
          />
          <g className="progress-line" transform={`scale(${progress} 1)`}>
            <path d="M0 0 L100 0" strokeWidth="2" stroke="#ffffff" />
          </g>
        </Progress>
        <Knob viewBox="0 0 38 38" progress={progress}>
          <circle
            r="18"
            cx="19"
            cy="19"
            stroke="#ffffff"
            fill="transparent"
            strokeWidth="2"
          />
          <circle r="8" cx="19" cy="19" fill="#337f65" />
        </Knob>
      </ProgressWrap>
      <Box pl="22px" height="100%" display="flex" alignItems="center">
        <span>{secondsToText(duration)}</span>
      </Box>
    </Container>
  )
}
